<template>
  <div class="container">
    <h4 class="text-center my-4">Список студентов</h4>

    <PrimePreloader v-if="loading"/>
    <div v-else class="my-3">

      <DataTable :value="surveyStudents" showGridlines stripedRows
                 :globalFilterFields="['barcode', 'last_name','first_name']"
                 v-model:filters="filters"
                 :paginator="true"
                 :rows="10"
                 paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                 :rowsPerPageOptions="[10,20,50]"
                 responsiveLayout="scroll"
                 currentPageReportTemplate="Показано с {first} по {last} из {totalRecords}">
        <template #header>
          <div class="p-d-flex p-jc-between p-ai-center">
            <span class="p-input-icon-left">
              <i class="pi pi-search"/>
              <InputText v-model="filters['global'].value" placeholder="Поиск студента"/>
            </span>
          </div>
        </template>

        <Column header="№" :style="{width: '60px'}">
          <template #body="{index}">
            {{index+1}}
          </template>
        </Column>
        <Column field="barcode" header="Баркод"></Column>

        <Column header="ФИО">
          <template #body="{data}">
            {{data.last_name}} {{data.first_name}} {{data.middle_name}}
          </template>
        </Column>

        <Column header="ОП">
          <template #body="{data}">
            {{data.education_speciality_code}} {{data.education_speciality_name}}
          </template>
        </Column>

        <Column header="Курсы">
          <template #body="{data}">
            <ul>
              <li v-for="(item, index) in data.courses"
                  :key="index">
                {{item.course_name}}
              </li>
            </ul>
          </template>
        </Column>

        <Column header="Анкетирование">
          <template #body="{data}">
            <router-link :to="'/survey?student_id=' + data.student_id + '&semester='+data.semester" target="_blank">
              Анкетирование
            </router-link>
          </template>
        </Column>



      </DataTable>






    </div>





  </div>
</template>

<script>
import {mapActions, mapMutations, mapState} from 'vuex'
import {FilterMatchMode, FilterOperator} from "primevue/api";


export default {
  name: 'SurveyStudents',
  data() {
    return {
      loading: true,

      filters: {
        'global': {value: null, matchMode: FilterMatchMode.CONTAINS},
        'barcode': {
          operator: FilterOperator.AND,
          constraints: [{value: null, matchMode: FilterMatchMode.STARTS_WITH}]
        },
        'last_name': {
          operator: FilterOperator.AND,
          constraints: [{value: null, matchMode: FilterMatchMode.STARTS_WITH}]
        },
        'first_name': {
          operator: FilterOperator.AND,
          constraints: [{value: null, matchMode: FilterMatchMode.STARTS_WITH}]
        },
      },

      // students: [
      //   {
      //     id: 1,
      //     student_id: 2157,
      //     barcode: 2112021,
      //     last_name: 'Иванов',
      //     first_name: 'Иван',
      //     middle_name: 'Иван',
      //     education_speciality_code: '6B00078',
      //     education_speciality_name: 'Устойчивый туризм',
      //     courses:[{
      //       id: 1,
      //       mdl_course_id: 27,
      //       semester: 2,
      //       name: 'Основы индустрии гостеприимства 2-семестр 22/23 1481G1',
      //     },
      //     {
      //       id: 2,
      //       mdl_course_id: 105,
      //       semester: 2,
      //       name: 'История туризма 2-семестр 22/23 1482G1',
      //
      //     }]
      //   }
      // ],


    }
  },
  computed: {
    ...mapState('survey', ['surveyStudents'])
  },

  methods: {
    ...mapActions('survey', ['GET_SURVEY_STUDENTS']),

  },
  async mounted() {
    await this.GET_SURVEY_STUDENTS()

    this.loading = false
  },
}

</script>